<template>
  <CardUI>
    <template v-slot:header>
      <h4>Выберите фотографию</h4>
    </template>
    <template v-slot:body>
      <v-row align="center">
        <v-col cols="12" md="2">
          <v-avatar class="mx-auto d-block" color="white" size="96" style="border: 2px solid #0090ff;">
            <v-img :src="avatarImg"></v-img>
          </v-avatar>
        </v-col>
        <v-col cols="12" md="10">
          <v-file-input id="avatar" :error-messages="errors.avatar" label="Выберите аватар" prepend-icon="mdi-camera"
                        type="file"
                        @change="onChange"
                        @click:clear="avatarImg = user.avatar"
          ></v-file-input>
          <ButtonUI :loading="loading" v-if="avatar" color="primary" outlined small @click="save">Сохранить</ButtonUI>
        </v-col>
      </v-row>


    </template>

  </CardUI>
</template>

<script>
import user from "@/api/user.js";
import analytics from "@/api/analytics.js";
import CardUI from "@/components/UI/CardUI.vue";
import ButtonUI from "@/components/UI/ButtonUI";

export default {
  name: "AvatarUpdateComponent",
  components: {ButtonUI, CardUI},
  data() {
    return {
      avatar: "",
      avatarImg: "",
      loading: false,
      errors: {}
    };
  },
  methods: {
    save() {
      this.loading = true;
      this.errors = {};
      let bodyFormData = new FormData();
      bodyFormData.append('avatar', this.avatar);
      user.update_avatar({id: this.user.id, fd: bodyFormData}).then(() => {
        this.loading = false;
        this.$emit('updatedAvatar')
        analytics.event({
          event: 'USER_UPDATE_AVATAR',
          details: {}
        })
        user.item({id: this.user.id}).then((r) => {
          this.avatarImg = r.data.avatar;
          this.avatar = null;
        });
      }).catch((e) => {
        if (e.response.data) {
          this.errors = e.response.data.errors;
        }
        this.loading = false;
      })
    },
    onChange(event) {
      // eslint-disable-next-line no-console
      console.log(event);
      this.avatar = event;
      let reader = new FileReader();
      reader.onload = (e) => {
        this.avatarImg = e.target.result;
      }
      reader.readAsDataURL(event);
    }
  },
  props: ['user'],
  mounted() {
    this.avatarImg = this.user.avatar;
  }
}
</script>

<style scoped>

</style>
