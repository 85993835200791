<template>
  <v-container style="max-width: 980px; margin: 0 auto" :fluid="$store.state.user.fluid">
    <v-progress-circular class="mx-auto d-block mt-10" v-if="loading"
                         :size="40"
                         width="2"
                         color="primary"
                         indeterminate
    ></v-progress-circular>
    <div v-else>
      <v-alert v-if="user.necessary_fields.redirect" style="white-space: pre-line" dark type="error">
        {{ user.necessary_fields.problem }}
      </v-alert>
      <v-alert v-else type="success">
        Профиль полностью заполнен. Спасибо!
      </v-alert>
      <CardUI class="my-2">
        <template v-slot:header>
          <h3>
            Основные данные
          </h3>
        </template>
        <template v-slot:body>
          <InputUI v-model="user.last_name"
                   class="my-4" outlined
                   placeholder="Фамилия"></InputUI>
          <InputUI
              v-model="user.first_name"
              placeholder="Имя"></InputUI>

          <InputUI
              v-model="user.third_name"
              placeholder="Отчество"></InputUI>


          <v-alert v-if="!user.birthday_date" type="error">
            Укажите дату рождения
          </v-alert>
          <DatePickerUIDecorator class="my-4">
            <DatePicker :value="user.birthday_date" format="DD.MM.YYYY"
                        input-class="date-input" placeholder="Дата рождения"
                        type="date" value-type="YYYY-MM-DD"
                        @input="user.birthday_date =  $event;"></DatePicker>
          </DatePickerUIDecorator>

          <InputUI
              v-model="user.email"
              :read-only="true"
              label="Email" type="email"/>

          <h4>Роль в АИС</h4>
          <SelectUI class="my-2" v-model="user.role" :items="[
                    {id: 'employee', name: 'Сотрудник'},
                    {id: 'student', name: 'Студент'},
                    {id: 'pupil', name: 'Ученик школы'},
                    {id: 'visitor', name: 'Посетитель'},
                    {id: 'company', name: 'Организация'},
                    ]" item-text="name" item-value="id" placeholder="Роль"/>

          <v-row v-if="user.role === 'student'" transition="scroll-y-transition">
            <v-col lg="12">

              <SelectUI v-model="user.student_groups_id"
                        :items="$store.state.dictionaries.studentGroups"
                        class="my-4"
                        hint="Выберите существующую или укажите свою, если ее нет в списке"
                        item-text="name"
                        item-value="id"
                        placeholder="Учебная группа"
              />


            </v-col>
          </v-row>
          <v-row v-if="user.role === 'employee'" transition="scroll-y-transition">
            <v-col lg="12">
              <SelectUI
                  v-model="user.department_id"
                  :items="$store.state.dictionaries.departments"
                  item-text="name"
                  item-value="id"
                  placeholder="Структурное подразделение"
              />

              <InputUI v-model="user.employee_post"
                       class="my-4"
                       outlined
                       placeholder="Должность"/>


            </v-col>
          </v-row>
          <v-row v-if="user.role === 'pupil'" transition="scroll-y-transition">
            <v-col lg="12">
              <InputUI
                  v-model="user.pupil_school"
                  outlined
                  placeholder="Учебное заведение"/>

              <InputUI
                  v-model="user.pupil_class"
                  outlined
                  placeholder="Класс"/>


            </v-col>
          </v-row>

          <ButtonUI :disabled="!validation" :loading="loading" class="my-4" text type="default" @click="save">
            Сохранить
          </ButtonUI>
        </template>

      </CardUI>
      <AvatarUpdateComponent @updatedAvatar="load()" :user="user"/>
      <ConfirmationPhoneComponent v-if="user.id === $store.state.user.currentUser.id" class="mt-2"/>
    </div>
  </v-container>
</template>

<script>
import user from "@/api/user";
import {mask} from 'vue-the-mask'
import AvatarUpdateComponent from "@/components/Profile/AvatarUpdateComponent.vue";
import InputUI from "@/components/UI/InputUI.vue";
import DatePickerUIDecorator from "@/components/UI/DatePickerUIDecorator.vue";
import DatePicker from "vue2-datepicker";
import SelectUI from "@/components/UI/SelectUI.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";
import ConfirmationPhoneComponent from "@/components/Profile/ConfirmationPhoneComponent.vue";
import CardUI from "@/components/UI/CardUI.vue";

export default {
  directives: {
    mask,
  },
  components: {
    ButtonUI,
    SelectUI,
    ConfirmationPhoneComponent,
    InputUI,
    AvatarUpdateComponent,
    DatePicker,
    DatePickerUIDecorator,
    CardUI
  },
  computed: {
    validation() {
      return this.user && this.user.birthday_date && this.user.birthday_date.length === 10
    },
  },
  name: "EditProfileComponent",
  data() {
    return {
      user: null,
      loading: true,
      update: false
    }
  },
  methods: {
    save() {
      this.loading = true;
      user.store(this.user).then((r) => {
        this.user = r.data;
        this.user.birthday_date = this.$moment.utc(r.data.birthday_date).local().format('YYYY-MM-DD');
        this.loading = false;
        this.update = true;
        if (parseInt(this.user.id) === parseInt(this.$store.state.user.currentUser.id) && this.user.necessary_fields.redirect !== this.$store.state.user.currentUser.necessary_fields.redirect) {
          window.location.reload()
        }
        setTimeout(() => {
          this.update = false;
        }, 2000);
      })
    },
    load() {
      user.item({id: this.$route.params.id}).then((r) => {
        this.user = r.data;
        this.loading = false;
        // this.user.birthday_date = "2000-02-04"
        this.user.birthday_date = this.$moment.utc(r.data.birthday_date).local().format('YYYY-MM-DD');
        if (this.user.necessary_fields.redirect !== this.$store.state.user.currentUser.necessary_fields.redirect) {
          window.location.reload()
        }
      })
    }
  },
  mounted() {
    this.$emit('changeTitle', 'Редактирование профиля ')
    if (this.$store.state.user.currentUser.id != this.$route.params.id && !this.$store.getters.checkPermission('can_manage_users')) {
      this.$router.back();
    } else {
      this.load();
    }
  }
}
</script>

<style scoped>

</style>
